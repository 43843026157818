<template>
  <div class="setting-item s-hint-text">
    <esmp-input
      v-model="localSetting.value"
      :label="localSetting.name"
      :options="{ type: 'textarea' }"
    />
  </div>
</template>

<script>
export default {
  name: 'SHintText',
  model: {
    prop: 'setting',
    event: 'input',
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
  },
  computed: {
    localSetting: {
      get() {
        return this.setting;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
